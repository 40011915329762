var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-top"},[_c('el-form',{attrs:{"label-position":"right","label-width":"85px","inline":true}},[_c('el-form-item',{attrs:{"label":"时间筛选："}},[_c('el-date-picker',{staticStyle:{"width":"250px"},attrs:{"type":"daterange","range-separator":"至","size":"small","start-placeholder":"开始日期","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions,"end-placeholder":"结束日期"},on:{"change":function($event){return _vm.handleSearch()}},model:{value:(_vm.form.Time),callback:function ($$v) {_vm.$set(_vm.form, "Time", $$v)},expression:"form.Time"}})],1),_c('el-form-item',{attrs:{"label":"客户："}},[_c('el-input',{staticStyle:{"width":"200px"},model:{value:(_vm.form.PurchaserName),callback:function ($$v) {_vm.$set(_vm.form, "PurchaserName", $$v)},expression:"form.PurchaserName"}})],1),_c('el-button-group',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)],1),_c('div',{staticStyle:{"width":"100%","height":"250px"}},[_c('div',{ref:"chart",staticStyle:{"width":"100%","height":"100%","margin-top":"10px"}})]),_c('div',{staticClass:"page-table",staticStyle:{"margin":"0px 10px 0px 10px"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableConfig.loading),expression:"tableConfig.loading"}],ref:"reportTable",attrs:{"data":_vm.tableConfig.data,"show-summary":"","border":"","height":"100%","header-cell-style":{ 'background-color': '#E5EEF6', 'border-color': 'rgba(218, 223, 228, 1)', height: '48px', }}},[_c('el-table-column',{attrs:{"prop":"PurchaserName","label":"客户名称","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.PurchaserName + (row.TypeStr == '' ? '' : '[' + row.TypeStr + ']'))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"PickingNum","label":"拿货商品数","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.PickingNum)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"PickingAmount","label":"拿货金额","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.PickingAmount)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"RefundNum","label":"退货商品数","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.RefundNum)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"RefundAmount","label":"退货金额","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.RefundAmount)+" ")]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }